$red: #f04568;

$gunmetal: #2c3539;

.cardEffect {
  position: relative;
  cursor: pointer;
}

.cardEffect .face {
  width: 300px;
  height: 200px;
  transition: 0.5s;
}

.cardEffect .face.face1 {
  position: relative;
  background: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transform: translateY(100px);
}

.cardEffect:hover .face.face1,
.cardEffect:active .face.face1 {
  background: $red;
  transform: translateY(0);
}

.cardEffect .face.face1 .content {
  opacity: 0.2;
  transition: 0.5s;
}

.cardEffect:hover .face.face1 .content,
.cardEffect:active .face.face1 .content {
  opacity: 1;
}

.cardEffect .face.face1 .content img {
  max-width: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.cardEffect .face.face1 .content h3 {
  margin: 10px 0 0;
  padding: 0;
  color: turquoise;
  text-align: center;
  font-size: 1.5em;
}

.cardEffect .face.face2 {
  position: relative;
  background: turquoise;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
  transform: translateY(-100px);
}

.cardEffect:hover .face.face2,
.cardEffect:active .face.face2 {
  transform: translateY(0);
}

.cardEffect .face.face2 .content ul {
  text-align: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-bottom: 5px;
}

.cardEffect .face.face2 .content a {
  text-decoration: none;
  font-weight: 900;
  color: #333;
  padding: 5px;
  border: 1px solid #333;
  & i {
    font-size: 20px;
  }
}
.links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.cardEffect .face.face2 .content a:hover {
  background: $gunmetal;
  color: $red;
}
