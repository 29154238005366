@mixin absCenter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

$a-tags: 'a, a:link, a:visited, a:hover, a:active';

$red: #f04568;

$gunmetal: #2c3539;

$body-background-color: $gunmetal;

$footer-padding: 0.5rem 1.5rem 0.5rem;
$footer-background-color: turquoise;

$content-heading-color: turquoise;

.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navbar-brand button {
  border: none;
  background-color: $red;
}

.home {
  color: turquoise;
  text-align: center;
  padding-left: 10vw;
  padding-right: 10vw;
  & span {
    color: $red;
  }
}

.about-div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;
  text-align: center;
  width: 50%;
}

.aboutme,
.skills,
.current {
  text-align: center;
  & h1 {
    color: $red;
  }
  & p {
    color: turquoise;
  }
}

.figures {
  display: flex;
  justify-content: space-evenly;
}

figure {
  color: turquoise;
  & i {
    padding: 0 5px;
  }
}

.form {
  display: grid;
  place-items: center;
}

@media (max-width: 768px) {
  .form iframe {
    width: 350px;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .form iframe {
    width: 640px;
  }
}

.projects {
  display: flex;
  justify-content: space-evenly;
}

@media (max-width: 768px) {
  .projects {
    flex-direction: column;
    margin: 0 auto;
  }
}

.resume {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  color: turquoise;
  text-align: center;
  & img {
    vertical-align: middle;
  }
  & h1 {
    color: $red;
  }
  & li + li {
    margin-top: 50px;
  }
}

.footer-div {
  width: 100%;
  height: 4%;
  background-color: turquoise;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 15px;
  text-align: center;
}

.footer-right {
  & li + li {
    margin-left: 5px;
  }
  & li {
    display: inline;
    font-size: 30px;
    #{$a-tags} {
      color: black;
    }
  }
}

@import '../node_modules/bulma/bulma.sass';
